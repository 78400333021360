.toolbar-container {
    min-height: 52px;
    /*background: rgb(164, 189, 170);*/
    background: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button {
    padding: 4px;
}

.tool-buttons-container {
    display: flex;
    align-items: center;
}

.search-container {

}