.pagination-container {
    height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.pagination-panel {
    padding-right: 10px;
    padding-left: 10px;
}

.pagination-rowPerPage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 350px;
}