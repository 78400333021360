.chat-container {
    display: flex;
    flex-direction: column;
}

.chat-status {
    position: fixed;
    font-size: 13px;
    font-style: italic;
    bottom: 60px;
}