.chat-toolbar-container {
    min-height: 52px;
    background: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.chat-tool-buttons-container {
    display: flex;
    align-items: center;
}

