.table-container {
    /*width: calc(100vw - 150px);*/
    height: calc(100vh - 195px);
    overflow: auto;
    border: 1px solid #b8b8b8;
}

.headerCellContainer {
    width: 100%;
    height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sortLabel {
    position: relative;
    height: 24px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.sortLabel:hover {
    font-weight: 600;
}

.sortTitle {
    padding-right: 4px;
}

.filtered-column {
    font-weight: bold;
    font-style: italic;
}

.sortArrow {
    display: block;
    text-align: center;
    transition: transform .3s;
}

.arrowUp {
    transform: rotate(-90deg);
}

.arrowDown {
    transform: rotate(90deg);
}

.tableRowIsSelected {
    background-color: #b9e2b9
}

.table-container table {
    border-spacing: 0;
    border-right: 1px solid #bbbbbb;
}


.table-container th {
    border: 1px solid #bbbbbb;
    padding: 5px;
    position: sticky;
    top: 0;
    background: #b8b8b8;
    color: #070e14;
    font-weight: normal;
}

.table-container td {
    padding: 5px;
    border-bottom: 1px solid #bbbbbb;
}

.not_found-cell {
    height: 100px;
    text-align: center;
}


.cellResize {
    top: 0;
    width: 8px;
    right: 0;
    height: 100%;
    cursor: col-resize;
    z-index: 100;
    position: absolute;
}

.resizeMarkCommon {
    top: 25%;
    width: 1px;
    height: 50%;
    position: absolute;
    transition: all linear 100ms;
    background-color: #e5e6e7;
    opacity: 0;
}

.resizeMarkLeft {
    left: 3px;
}


.resizeMarkRight {
    right: 2px;
}


.showDividers {
    opacity: 1;
}


/* подсветка при наведении*/
.table-container tr:hover {
    background-color: #f5f1f1;
}

/*для фиксирования первой колонки*/
.table-container th:nth-child(1),
.table-container td:nth-child(1) {
    position: sticky;
    left: 0;
}

.table-container td:nth-child(1) {
    background: #fff;
}

.table-container th:nth-child(1) {
    z-index: 2;
}

