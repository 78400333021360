.paper {
    margin: 48px;
    display: flex;
    outline: none;
    position: relative;
    flex-direction: column;
    overflow-y: initial;
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
}

.savedFilterList {
    margin-top: auto;
    padding: 10px;
}

.dialogContent {
    padding: 0
}

.selectTextFilterButtonsContainer {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
}
