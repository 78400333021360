.body-container {
    background-color: #fff;
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 170px);
}

.container > * {
    margin-bottom: 10px;
}


.chats > p {
    font-size: 13px;
}

.senderName {
    text-align: right;
}

.messageRecipient {
    background-color: #f9efec;
    width: 300px;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
}

.messageSender {
    background-color: rgba(190, 232, 133, 0.85);
    max-width: 300px;
    padding: 10px;
    border-radius: 10px;
    font-size: 15px;
    margin-left: auto;
}
