.card-container {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
}

.card {
    min-width: 500px;
    margin: 10px;
    background-color: #ecf8f0;
}

.card-content {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.card-toolbar-container {
    min-height: 52px;
    background: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 10px;
}

.card-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-button {
    padding: 4px;
}

.card-list-container {
    height: calc(100vh - 150px);
    overflow: auto;
}